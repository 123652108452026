<template>
  <h1>Payment success</h1>
</template>

<script>
export default {
  name: "Success",
};
</script>

<style scoped></style>
